import React, { useState, useEffect } from 'react';
import 'web3';
import './css/Metamask.css';
import './css/Animation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAccount } from 'wagmi'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'

const MetamaskInfoComponent = () => {

  const [userAccount, setUserAccount] = useState('');
  const [contractAddress, setContractAddress] = useState('');  
  const [callbackUrl, setCallbackUrl] = useState('');
  const [mode, setMode] = useState('');
  const { address } = useAccount(); 
  const [loginStatus, setLoginStatus] = useState(0);
  useEffect(() => {
    const initMetamask = async () => {
      try {
        // if (window.ethereum) {
          // const accounts = await window.ethereum.enable();
          // const currentUserAccount = accounts[0];    
          const currentUserAccount = address;
          setUserAccount(currentUserAccount);
          // let currentNetworkName;
          // switch (networkId) {
          //   case 1:
          //     currentNetworkName = 'Mainnet';
          //     break;
          //   case 3:
          //     currentNetworkName = 'Ropsten Testnet';
          //     break;
          //   case 4:
          //     currentNetworkName = 'Rinkeby Testnet';
          //     break;
          //   case 5:
          //     currentNetworkName = 'Goerli Testnet';
          //     break;
          //   case 42:
          //     currentNetworkName = 'Kovan Testnet';
          //     break;
          //   default:
          //     currentNetworkName = 'Unknown';
          // }
          // setNetworkName(currentNetworkName);

          // URLのクエリパラメーターからcontractの値を取得する
          const urlParams = new URLSearchParams(window.location.search);
          const currentContractAddress = urlParams.get('contract');
          setContractAddress(currentContractAddress);

          const currentCallbackUrl = urlParams.get('callbackUrl');
          setCallbackUrl(currentCallbackUrl);

          const currentMode = urlParams.get('mode');
          setMode(currentMode);

          const res = await fetch(`https://polygon-mainnet.g.alchemy.com/nft/v2/NAzGx64phxlIlZ73SJHs5NvxcpatTLS0/getNFTs?owner=${currentUserAccount}&contractAddresses[]=${currentContractAddress}&withMetadata=false&pageSize=100&callback=${currentCallbackUrl}`);
          //const res = await fetch(`https://polygon-mainnet.g.alchemy.com/nft/v2/NAzGx64phxlIlZ73SJHs5NvxcpatTLS0/getNFTs?owner=${currentUserAccount}&contractAddresses[]=${currentContractAddress}&withMetadata=false&pageSize=100`)
          const resJson = await res.json()
            
          if(resJson.totalCount>0){
            setLoginStatus(1)
          }
          else{
            setLoginStatus(0)
          }
          console.log(JSON.stringify(resJson))
        // } else {
        //   console.error('Metamaskが見つかりません。');
        // }
      } catch (error) {
        console.error(error);
      }
    };

    initMetamask();
  }, [address]);

//URLリダイレクト
  useEffect(() => {
    if (loginStatus === 1 && mode === '2' && callbackUrl) {
      fetch(callbackUrl).then(response => {
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        // ここでレスポンスの処理を続けます
      }).catch(err => {
        alert(`コールバック処理時にエラーが発生しました。施設やサービスの管理者にご連絡ください。`);
        console.error(err);
      });
    }
  }, [loginStatus, callbackUrl, mode]);

  return (
    <div className='content'>
      {/* <p>User Account: {userAccount}</p> */}
      {/* <p>Contract Address: {contractAddress}</p> */}

      {() => {
        if (loginStatus === 1) {
          return <div>OK</div>
        } else if (loginStatus === 0) {
          return <div>NG</div>
        }
      }}

      {address ? (loginStatus === 1 ? (
         <>
          <Card style={{ width: '18rem' }}>
            <Card.Img />
              <Card.Body>
                <Card.Text>
                  <div className='Login-true'>
                    <div className='checkmark-container'>
                      <div className='checkmark'>
                        <div className='checkmark_stem'></div>
                        <div className='checkmark_kick'></div>
                      </div>
                    </div>
                    <h4 className='element1-1'>ログイン成功</h4>
                    <p className='element1-2'>akichiにようこそ！</p>
                    {mode === '1' ? (
                      <Button href={callbackUrl} className='callback_button'>サイトに移動</Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </Card.Text>
                </Card.Body>
          </Card>
         </>
      ) : (
        <>
          <Card style={{ width: '18rem' }}>
            <Card.Img />
              <Card.Body>
                <Card.Text>
                  <div className='Login-false'>
                    <div className='errormark-container'>
                      <div className='errormark'>
                        <div className='errormark_kick'></div>
                      </div>
                    </div>
                    <h4 className='element2-1'>ログイン失敗</h4>
                    <p className='element2-2'>ウォレットを確認してください</p>
                  </div>
        </Card.Text>
        </Card.Body>
  </Card>
        </>
      )):(<></>)}
      {/* <p className='loginStatus'>loginStatus: {loginStatus}</p> */}
    </div>
  );
};

export default MetamaskInfoComponent;
