import MetamaskInfoComponent from './components/MetamaskInfoComponent.js'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
// import NetworkSwitcher from './components//NetworkSwitcher'; // NetworkSwitcherをインポート
import 'App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { WagmiProvider } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import imagePark from 'img/park2.png'
import logo from 'img/logo.png'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '179e1585f45393b1396319bd54738e8b'

// 2. Create wagmiConfig
const metadata = {
  name: 'Akichi',
  description: 'Akichi Login System',
  url: 'https://akichi.studymeter.jp', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const chains = [mainnet, arbitrum, polygon] as const
const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
})

export function App({}) {
  return (
    <div>
      <div className="App">
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <header>
              <img
                className="title-image"
                src={logo}
                alt="Your Image"
                width="200px"
              />
              {/* <h1 className="main-titel">Akichi 2 1/2</h1> */}
            </header>
            <div className="loginButton">
              <w3m-button />
            </div>
            <div className="element-div">
              <div className="element-child">
                <a
                  className="element-a"
                  href="https://gainful-dinghy-88c.notion.site/akichi-b5c4d17ef6354cada389b40feb1fb3af?pvs=74"
                  target="_blank"
                  rel="noreferrer"
                >
                  使い方
                </a>
                <a
                  className="element-a"
                  href="https://fops.studymeter.jp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  運営団体
                </a>
                <a
                  className="element-a"
                  href="https://fops.studymeter.jp/#contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  お問合せ
                </a>
                <a
                  className="element-a"
                  href="https://studymeter.jp/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  プライバシー
                </a>
                <a
                  className="element-a"
                  href="https://studymeter.jp/termsofuse"
                  target="_blank"
                  rel="noreferrer"
                >
                  利用規約
                </a>
              </div>
              <div className="element-child">
                ©︎ First off Projects and Studymeter Inc.
              </div>
            </div>

            <div
              className="bottom-image"
              style={{
                backgroundImage: `url(${imagePark})`,
                backgroundSize: '100% auto',
              }}
            ></div>
          </QueryClientProvider>
          <MetamaskInfoComponent /> {/* MetamaskInfoComponentを表示 */}
          {/* <NetworkSwitcher /> NetworkSwitcherコンポーネントを表示 */}
        </WagmiProvider>
      </div>
    </div>
  )
}

export default App
